.Style {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px; }
.StyleTiles-Container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 32px;
  margin-top: 48px; }
.Info-Container {
  padding: 24px;
  width: 100%;
  border: 1px #BEBEBE solid;
  border-radius: 24px;
  margin-top: 24px;
  .Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px; } }

.StyleTile {
  padding: 24px;
  color: #fff;
  height: 280px;
  background-size: cover;
  background-position: top center;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  .Title {
    line-height: 34px; }
  .Title, .Description {
    position: relative;
    z-index: 1; }
  .Title {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 900; }
  .Description {
    font-size: 16px; } }

@media screen and ( max-width: 1024px ) {
  .StyleTiles-Container {
    grid-template-columns: repeat(3, minmax(0, 1fr)); } }
@media screen and ( max-width: 768px ) {
  .StyleTiles-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }
@media screen and ( max-width: 576px ) {
  .StyleTiles-Container {
    grid-template-columns: 1fr; } }
