.Portfolio {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px; }
.PortfolioCarousel {}

.PortfolioTile {
  width: 100%;
  border: 1px var(--lightGray) solid;
  border-radius: 8px;
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  .Content {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px; }
  .Title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700; }
  .ParamData {
    align-items: flex-start;
    break-inside: avoid;
    display: flex;
    justify-content: space-between;
    page-break-inside: avoid;
    .ParamTitle {
      align-items: flex-start;
      flex-shrink: 0; }
    .ParamDevider {
      border-bottom: 1px dotted #d3d4d4;
      content: "";
      flex: 1 1 12px;
      height: 11px;
      margin: 5px 6px 0 2px; }
    .ParamValue {
      display: inline-block;
      flex-shrink: 1;
      max-width: 50%;
      padding-left: 8px;
      text-align: right; } }
  .PriceBlock {
    margin-top: 16px;
    .Label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700; }
    .PriceValue {
      font-size: 22px;
      font-weight: 700; } } }
