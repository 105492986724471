.Top {
  width: 100%;
  height: 48px;
  background-color: var(--black);
  display: flex;
  justify-content: center; }

.Top-Container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center; }
.Top-Contacts-Container {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 32px; }

.Top-Contact-Buttons {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 32px; }

.Top-Service-Buttons {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 32px; }
@media screen and ( max-width:  1200px) {
  .Top {
    display: none; } }
