.About {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff; }
.About-Container {
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 32px; }
.AboutImg {
  width: 450px;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px; } }
.About-Text {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  color: var(--color-tertiary-8);
  .Benefits-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px; }
  .BenefitTile {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    .Step {
      font-size: 18px;
      background: var(--accent);
      width: 28px;
      height: 28px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 500;
      border-radius: 4px; }
    .Title {
      font-size: 16px;
      font-weight: 700; }
    .Description {
      color: var(--darkDescription);
      font-size: 14px; } }

  .Button {
    justify-self: start; } }

@media screen and ( max-width: 1200px ) {
  .About-Container {
    grid-template-columns: 1fr 450px;
    grid-gap: 24px; }
  .About-Text {
    .Benefits-Container {
      grid-template-columns: 1fr; } } }

@media screen and ( max-width: 768px ) {
  .About-Container {
    grid-template-columns: 1fr; }
  .AboutImg {
    width: 100%;
    order: 0;
    img {
      border-radius: 0 !important; } }
  .About-Text {
    order: 1; } }
