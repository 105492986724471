.SocialLinks {
  display: flex;
  align-items: center;
  .Title {
    color: #ffff;
    font-size: 16px; }
  a {
    color: var(--accent2); }
  .Icon {
    margin-left: 12px; } }
