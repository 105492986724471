.CalcForm {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .Description, label {
    color: var(--darkDescription); }

  .CalcFields {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 24px; }
  .CalcPhoneField {
    .Phone {
      grid-column: 1/3; } } }
