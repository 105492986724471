.RequestConsultation {
  background-color: var(--black);
  display: flex;
  justify-content: center;
  color: #fff;
  .SectionTitle {
    color: #fff;
    margin-bottom: 0; }
  a {
    color: #fff;
    text-decoration: underline !important;
    &:hover {
      color: var(--accent); } }

  .RequestConsultation-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    justify-items: center;
    padding: 64px 0px;
    background-repeat: no-repeat;
    background-position: top 40px left 100px; } }

.RequestConsultation-Form {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 24px; }

@media screen and ( max-width: 768px ) {
  .RequestConsultation {
    text-align: center; }
  .RequestConsultation-Form {
    grid-template-columns: 1fr; } }
