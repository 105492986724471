.Calc {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px; }
.Calc-Container {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 48px;
  align-items: start;
  .Title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase; } }

.KitchenForms {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
  .Title {
    grid-column: 1/-1; } }

.KitchenFormTile {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px var(--description) solid;
  height: 180px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 2px var(--accent2) solid;
    img {
      filter: brightness(0) saturate(100%) invert(68%) sepia(50%) saturate(434%) hue-rotate(349deg) brightness(91%) contrast(89%); } }
  .Title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin-top: 24px; } }
