.Oplata {
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  // .Oplata-Container
  //   display: flex
  //   justify-content: center
  // .OplataForm
  //   width: 400px
  .Site-StatusMessage {
    margin-bottom: 24px; }
  .Button {
    margin-top: 24px; }
  .SectionTitle {
    margin-bottom: 48px; } }
