.Site-Hero {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  background-color: var(--background);
  align-items: center;
  position: relative;
  .HeroBg {
    height: 100%;
    position: absolute;
    width: 600px;
    background-size: cover;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 300px;
    border-bottom-left-radius: 250px; }
  .Offer {
    width: 540px;
    .Title {
      font-size: 42px;
      font-weight: 700;
      line-height: 52px; }
    .Description {
      font-size: 18px;
      color: var(--darkDescription);
      margin-top: 16px; }
    .Benefits {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 24px; }
    .Benefit {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      .Icon {
        color: var(--accent);
        font-size: 22px;
        margin-right: 12px;
        margin-top: 4px; } }
    .Buttons {
      margin-top: 24px;
      display: flex;
      .Button {
        &:first-child {
          margin-right: 24px; } } } } }

@media screen and ( max-width: 1024px ) {
  .Site-Hero {
    .HeroBg {
      width: 100%;
      height: 400px;
      left: 0;
      margin-left: 0;
      transform: none;
      z-index: 1;
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.3);
        top: 0;
        left: 0;
        position: relative; } }
    .Offer {
      width: 100%;
      position: relative;
      z-index: 3;
      color: #fff;
      .Description {
        color: #fff; } } } }

@media screen and ( max-width: 576px ) {
  .Site-Hero {
    height: 100%;
    padding: 24px 0px;
    .HeroBg {
      height: 100%; }
    .Offer {
      .Benefits {
        grid-template-columns: 1fr; }
      .Buttons {
        .Button {
          width: 100%;
          margin-right: 0 !important; } } } } }
