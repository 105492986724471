.Site-Nav {
  width: 100%;
  height: 64px;
  background-color: var(--accent);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.4s ease; }
.Nav-Container {
  display: flex;
  align-items: center;
  justify-content: space-between; }
.Site-Nav_theme_shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07); }
.Site-Nav-Menu {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 32px;
  align-items: center;
  justify-self: end; }

.Site-NavLink {
  color: #fff !important;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500; }
.MobileSocial {
  display: none; }

@media screen and ( max-width:  1200px) {
  .Site-Hamburger-Container {
    flex-shrink: 0;
    margin-right: 16px; }
  .Nav-Container {
    justify-content: flex-start; }
  .MobileSocial {
    margin-left: auto;
    display: block;
    .PhoneLinks {
      margin-right: 24px; }
    .Icon, .Description {
      color: #fff; } }
  .Site-Nav-Menu {
    display: none;
    position: absolute;
    top: 64px;
    left: 0px;
    grid-template-columns: 1fr;
    width: 100%;
    background-color: #fff;
    padding: 16px 24px;
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    height: auto;
    grid-template-rows: repeat(5, minmax(0, max-content));
    grid-gap: 8px; }
  .Site-NavLink {
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px var(--lightGray) solid;
    font-size: 16px;
    padding: 8px 0px;
    &:hover {
      background: none; }
    &:last-child {
      border-bottom: 0; } }
  .Site-NavLink_theme_active {
    color: var(--lightGray);
    background: none; } }
@media screen and ( max-width: 992px ) {
  .MobileSocial {
    .PhoneLinks {
      margin-right: 24px; }
    .SocialLinks {
      display: none; } } }
@media screen and ( max-width: 576px ) {
  .Nav-Container {
    .Logo {
 } }      // margin-left: 21px
  .MobileSocial {
    .PhoneLinks {
      margin-right: 0; }
    .EmailLink {
      display: none; } } }
