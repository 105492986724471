.EduBenefits {
  display: flex;
  justify-content: center;
  padding: 48px 0px; }
.EduBenefits-TilesContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px;
  margin-top: 48px; }
.EduBenefitTile {
  text-align: center;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  display: grid;
  .Title {
    font-size: 18px;
    text-transform: uppercase;
    color: var(--black); }
  .Description {
    color: var(--darkDescription); }
  img {
    height: 64px; } }

@media screen and ( max-width: 576px ) {
  .EduBenefits-TilesContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }

@media screen and ( max-width: 400px ) {
  .EduBenefits-TilesContainer {
    grid-template-columns: 1fr; } }
