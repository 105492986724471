.Carousel {
  position: relative; }
.Carousel-DotsContainer {
  position: absolute;
  bottom: -36px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 12px;
  .Carousel-Dot {
    width: 12px;
    height: 12px;
    background-color: var(--lightGray);
    cursor: pointer;
    appearance: none;
    outline: none;
    display: block;
    border-radius: 4px;
    &:not(:last-child) {
      margin-right: 8px; }
    &:hover {
      background-color: var(--accent); } }
  .glide__bullet--active {
    background-color: var(--accent); } }
.Carousel-Arrow {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  border: 0px;
  background-color: #e53e43;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  border-radius: 4px;
  .Icon {
    font-size: 24px; }
  svg {
    color: #fff; }
  &:hover {
    background-color: var(--accent);
    svg {
      color: #fff; } } }
.Arrow-Left {
  left: -16px; }
.Arrow-Right {
  right: -16px; }

@media screen and ( max-width: 576px ) {
  .Carousel-Arrow {
    border-radius: 0; }
  .Arrow-Left {
    left: 0px; }
  .Arrow-Right {
    right: 0px; } }
