.SectionTitle {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color: var(--black);
  display: inline-block;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
  text-transform: uppercase;
  .Decorator {
    width: 42px;
    height: 42px;
    position: absolute;
    display: block;
    top: -18px;
    left: -42px; } }
@media screen and ( max-width: 576px ) {
  .SectionTitle {
    .Decorator {
      display: none; } } }
