.EmailLink {
  display: flex;
  .Icon {
    color: var(--accent2);
    font-size: 16px;
    margin-right: 18px;
    margin-top: 4px; }
  a {
    color: var(--accent2); }
  .Email {
    color: #fff;
    font-size: 16px; }
  .Content {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: start; }
  .Description {
    font-size: 10px;
    color: var(--darkDescription); } }
